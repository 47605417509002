<template>
  <div class="accommodation-settings">
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE_AND_NEW')"
      v-on:save="saveNew"
      :save_loading="save_loading"
      :btnSaveNew="true"
      :save_new_loading="save_new_loading"
      :textSaveNew="$t('LODGING.SAVE_EDIT')"
      v-on:save-new="saveEdit"
    />
    <!-- FORM LODGING -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- BASIC INFORMATION -->
      <div class="card card-custom card-stretch gutter-b w-100 margin-top--30">
        <div class="card-body">
          <BasicInformation :basic-info.sync="basicInformation" />
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { STORE_CAMPUS } from "@/core/services/store/general/campus.module";
import BasicInformation from "./components/BasicInformation";
import Buttons from "@/view/content/components/Buttons";
import { mapGetters } from "vuex";

export default {
  components: {
    BasicInformation,
    Buttons,
  },
  data() {
    return {
      valid: true,
      basicInformation: null,
      save_loading: false,
      save_new_loading: false,
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/campus");
    },

    /**
     * Save and create new campus
     * @method saveNew
     */
    saveNew() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;

        const {
          transition_rules,
          transitionRuleTypeId,
          cancellationPolicy,
          cancellationPoliciesDescription,
          freePostponePolicy,
          postponePolicy,
          standardCancellationPolicies,
          postponeGeneralPolicy,
          visaCancellationPolicy,
          PaymentPolicy,
          PaymentPolicyLongTerm,
          PaymentPolicyPercent,
          PaymentPolicyRecurrence,
          PaymentPolicySpecial,
          PaymentPolicySpecialPercent,
        } = this.company;

        const data = {
          ...this.basicInformation,
          campusId: null,
          iban: this.company.iban,
          bic: this.company.bic,
          aba: this.company.aba,
          phone: this.company.phone,
          email: this.company.email,
          website: this.company.website,
          transition_rules: transition_rules,
          transitionRuleTypeId: transitionRuleTypeId,
          cancellationPolicy: cancellationPolicy,
          cancellationPoliciesDescription: cancellationPoliciesDescription,
          freePostponePolicy: freePostponePolicy,
          postponePolicy: postponePolicy,
          standardCancellationPolicies: standardCancellationPolicies,
          postponeGeneralPolicy: postponeGeneralPolicy,
          visaCancellationPolicy: visaCancellationPolicy,
          PaymentPolicy: PaymentPolicy,
          PaymentPolicyLongTerm: PaymentPolicyLongTerm,
          PaymentPolicyPercent: PaymentPolicyPercent,
          PaymentPolicyRecurrence: PaymentPolicyRecurrence,
          PaymentPolicySpecial: PaymentPolicySpecial,
          PaymentPolicySpecialPercent: PaymentPolicySpecialPercent,
        };

        this.$store
          .dispatch(STORE_CAMPUS, data)
          .then(() => {
            this.save_loading = false;
            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            setTimeout(() => {
              this.$refs.form.reset();
            }, 2000);
          })
          .catch(() => {
            this.save_loading = false;
          });
      }
    },

    /**
     * Create new campus
     * @method saveEdit
     */
    saveEdit() {
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_new_loading = true;

        const {
          transition_rules,
          transitionRuleTypeId,
          cancellationPolicy,
          cancellationPoliciesDescription,
          freePostponePolicy,
          postponePolicy,
          standardCancellationPolicies,
          postponeGeneralPolicy,
          visaCancellationPolicy,
          PaymentPolicy,
          PaymentPolicyLongTerm,
          PaymentPolicyPercent,
          PaymentPolicyRecurrence,
          PaymentPolicySpecial,
          PaymentPolicySpecialPercent,
        } = this.company;

        const data = {
          ...this.basicInformation,
          campusId: null,
          iban: this.company.iban,
          bic: this.company.bic,
          aba: this.company.aba,
          phone: this.company.phone,
          email: this.company.email,
          website: this.company.website,
          transition_rules: transition_rules,
          transitionRuleTypeId: transitionRuleTypeId,
          cancellationPolicy: cancellationPolicy,
          cancellationPoliciesDescription: cancellationPoliciesDescription,
          freePostponePolicy: freePostponePolicy,
          postponePolicy: postponePolicy,
          standardCancellationPolicies: standardCancellationPolicies,
          postponeGeneralPolicy: postponeGeneralPolicy,
          visaCancellationPolicy: visaCancellationPolicy,
          PaymentPolicy: PaymentPolicy,
          PaymentPolicyLongTerm: PaymentPolicyLongTerm,
          PaymentPolicyPercent: PaymentPolicyPercent,
          PaymentPolicyRecurrence: PaymentPolicyRecurrence,
          PaymentPolicySpecial: PaymentPolicySpecial,
          PaymentPolicySpecialPercent: PaymentPolicySpecialPercent,
        };

        this.$store
          .dispatch(STORE_CAMPUS, data)
          .then(({ data }) => {
            this.save_new_loading = false;

            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });

            const { campusId } = data.campus;

            setTimeout(() => {
              this.$router.push(`/campus/edit/${campusId}`);
            }, 2000);
          })
          .catch(() => {
            this.save_new_loading = false;
          });
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("CAMPUS.TITLE"),
        route: "/campus",
      },
      { title: this.$t("GENERAL.NEW") },
    ]);
  },
  created() {},
  computed: {
    ...mapGetters(["company"]),
  },
};
</script>
